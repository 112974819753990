<template>
    <div>
        <b-container>
            <section class="profile">
                <b-row>
                <b-col cols="12" md="4" lg="3">
                    <ProfileNavigation />
                </b-col>
                    <b-col cols="12" md="8" lg="9">
                        <div class="sector pl-0 pr-0">
                            <h2 class="profile__title mb-3">My events</h2>
                            <p v-if="getEventError" class="custom-error mb-3 ml-3"> {{ getEventError }} </p>
                            <div class="profile__content mb-3">
                                <b-row class="mt-1">
                                    <b-col cols="6">
                                        <p v-if="getFilteredUserEvents.length == 0 && getEventsPending && !loader" class="no-results">No events</p>
                                    </b-col>
                                    <b-col cols="6">
                                        <router-link to="/add-event" class="details-button details-button--red float-right mt-0"><simple-line-icons icon="arrowRight" size="small" color="#504e70" />Add event</router-link>
                                    </b-col>
                                </b-row>
                            </div>
                            <div v-if="getFilteredUserEvents.length == 0 && loader" class="profile__content mt-1">
                                <Spinner size="medium" line-fg-color="#e91e63" />
                            </div>
                            <div id="top" v-else class="profile__content mt-1">
                                <paginate name="userEvents" :list="getFilteredUserEvents" tag="div" :per="6" class="paginate-list">
                                <b-row>
                                    <b-col v-for="event in paginated('userEvents')" :key="event.id" cols="12" lg="6">
                                        <div class="profile__element mt-2">
                                            <p class="profile__label">Event status</p>
                                            <p v-if="event.status == 1" class="profile__status profile__status--positive">verified</p>
                                            <p v-else-if="event.status == 2" class="profile__status profile__status--pending">pending</p>
                                            <p v-else-if="event.status == 3" class="profile__status profile__status--negative">denied</p>
                                            <p v-if="event.status == 3 && event.reason" class="profile__label mt-2">Reason:{{ event.reason }}</p>
                                        </div>
                                        <div class="events__element">
                                            <div v-if="event.ribbon" class="custom-ribbon custom-ribbon--right">
                                                <span class="custom-ribbon__text custom-ribbon__text--red">Featured</span>
                                            </div>
                                            <div class="events__image" v-lazy:background-image="event.url"></div>
                                            <div class="events__content">
                                                <p class="events__type">{{ event.type.text }}</p>
                                                <h2 class="events__title" v-line-clamp:22="1">{{ event.name }}</h2>
                                                <p v-html="event.desc" class="events__text" v-line-clamp:24="3"></p>
                                                <b-row>
                                                    <b-col cols="6">
                                                        <span v-line-clamp:22="1" class="events__place"><span class="agencies__star-tooltip" v-b-tooltip title="Location"><simple-line-icons icon="locationPin" size="small" color="#504e70" /></span>{{ event.city }}, {{ event.country.text }}</span>
                                                    </b-col>
                                                    <b-col cols="6">
                                                        <span class="events__date"><span class="agencies__star-tooltip" v-b-tooltip title="Date"><simple-line-icons icon="calendar" size="small" color="#504e70" /></span>{{ event.date }}</span>
                                                    </b-col>
                                                </b-row>
                                            </div>
                                            <div class="events__details">
                                                <router-link :to="{ name: 'my-event', params: { id: event.id } }" class="details-button details-button--full"><simple-line-icons icon="arrowRight" size="small" color="#504e70" />View details</router-link>
                                            </div>
                                        </div>
                                        <div class="profile__element mt-2">
                                            <b-row>
                                                <b-col cols="12" sm="4">
                                                    <router-link :to="{ name: 'my-event', params: { id: event.id } }" class="details-button details-button--full details-button--green mt-0"><simple-line-icons icon="eye" size="small" color="#fff" />Preview</router-link>
                                                </b-col>
                                                <b-col cols="12" sm="4">
                                                    <router-link :to="{ name: 'edit-event', params: { id: event.id } }" class="details-button details-button--full details-button--yellow mt-0"><simple-line-icons icon="note" size="small" color="#fff" />Edit</router-link>
                                                </b-col>
                                                <b-col cols="12" sm="4">
                                                    <button @click.prevent="deleteEvent(event.id, event.image)" :disabled="getDisabledEvent" :class="getDisabledEvent ? 'disabled-element' : ''" class="details-button details-button--full details-button--dark-red mt-0"><simple-line-icons icon="close" size="small" color="#fff" />Delete</button>
                                                </b-col>
                                            </b-row>
                                        </div>
                                    </b-col>
                                 </b-row>
                                </paginate>
                                <paginate-links @change="scrollToTop('top')" v-if="getFilteredUserEvents.length != 0" for="userEvents" :limit="3" :show-step-links="true" :classes="{'ul': 'pagination','.next > a': 'next-link','.prev > a': 'prev-link'}"></paginate-links>
                            </div>
                        </div>
                    </b-col>
                </b-row>
            </section>
        </b-container>
    </div>
</template>

<script>
import ProfileNavigation from '@/components/ProfileNavigation.vue'
import SimpleLineIcons from 'vue-simple-line'
import { mapGetters } from 'vuex'
import Spinner from 'vue-simple-spinner'
import VueSweetalert2 from 'vue-sweetalert2'
import VuePaginate from 'vue-paginate'
import MoveTo from 'moveto'
export default {
  components: {
    ProfileNavigation,
    SimpleLineIcons,
    Spinner,
    VueSweetalert2
  },
  data: function () {
    return {
      loader: true,
      paginate: ['userEvents']
    }
  },
  methods: {
    deleteEvent (id, imageName) {
      this.$swal({
        type: 'warning',
        title: 'Warning',
        text: 'Are you sure you want to delete this event?',
        showCancelButton: true,
        cancelButtonText: 'No',
        confirmButtonText: 'Yes',
        confirmButtonColor: '#f1352e',
        cancelButtonColor: '#13b81b',
        focusCancel: true
      }).then((result) => {
        if (result.value) {
          this.$store.dispatch('setDisabledEvent')
          this.$store.dispatch('deleteEvent', { id: id, image: imageName })
            .then(() => {

            })
            .catch(error => {
              this.$store.dispatch('clearDisabledEvent')
            })
        }
      })
    },
    scrollToTop (element) {
      const moveTo = new MoveTo({
        tolerance: 75,
        duration: 1000
      })

      const target = document.getElementById(element)

      moveTo.move(target)
    }
  },
  computed: {
    ...mapGetters([
      'getEventError',
      'getDisabledEvent',
      'getUserEvents',
      'getEventsPending'
    ]),
    getFilteredUserEvents () {
      var events = Object.values(this.getUserEvents).reverse()
      return events
    }
  },
  beforeCreate () {
    this.$store.dispatch('clearError')
    this.$store.dispatch('clearDisabledEvent')
    this.$store.dispatch('getUserEvents')
  },
  created () {
    setTimeout(function () { this.loader = false }.bind(this), 5000)
  }
}
</script>
